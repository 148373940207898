import commonConfig from './common';

import animationConfig from '../animation/_config';
import appConfig from '../app/_config';
import contextConfig from '../context/_config';
import cookieConfig from '../cookie/_config';
import footNotesConfig from '../footnotes/_config';
import formConfig from '../form/_config';
import glossaryConfig from '../glossary/_config';
import homeConfig from '../home/_config';
import menuConfig from '../menu/_config';
import pageConfig from '../page/_config';
import slideshowConfig from '../slideshow/_config';
import casestudiesConfig from '../casestudies/_config';
import filteredArticlesConfig from '../filtered-articles/_config';
import widgetConfig from '../widget/_config';
import interactiveGraphicConfig from '../interactive-graphic/_config';

const config = (di) => {
	commonConfig(di);

	animationConfig(di);
	appConfig(di);
	contextConfig(di);
	cookieConfig(di);
	formConfig(di);
	footNotesConfig(di);
	glossaryConfig(di);
	homeConfig(di);
	menuConfig(di);
	pageConfig(di);
	slideshowConfig(di);
	casestudiesConfig(di);
	filteredArticlesConfig(di);
	widgetConfig(di);
	interactiveGraphicConfig(di);

	const prefix = 'dlx-';
	const classPrefix = 'dlx-js-';

	di
		.setValue('dom/dataAttrPrefix', prefix)
		.setValue('dom/classPrefix', classPrefix)
		.setValue('dom/baseClassPrefix', prefix)
		;

		const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
		const baseUrl = bodyData.get('baseUrl');
		di
			.setValue('baseUrl', baseUrl)
			.setParam('PageLoader', 'baseUrl', baseUrl)
			.setParam('HashScroller', 'duration', 0.5)
			.setParam('Navigation', 'navigateSameUrl', true)
			.setValue('menuMediaQuery', '(max-width: 1024px)')
	;
};


export default config;
