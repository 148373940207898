import Player from '@vimeo/player';
import PageComponent from '../../common/component/page-component';

class HomeSlide extends PageComponent {
	constructor({
		root,
		element,
		urlData = 'vimeourl',
		videoData = 'video',
		activeClass = 'active',
		showContentDelay = 0.8,
		hideContentDelay = 0.2
	}) {
		super({root: root, element: element});
		this.urlData = urlData;
		this.videoData = videoData;
		this.activeClass = activeClass;
		this.showContentDelay = showContentDelay;
		this.hideContentDelay = hideContentDelay;
	}

	prepare() {
		this.image = this.getComponent('AsyncPicture');
		this.player = null;
		this.initiated = false;
		this.isSlideActive = false;
		this.isVideoCreated = false;
		this.videoElement = this.element.querySelector(this.dataSelector(this.videoData));
	}

	initialize() {
		this.loadImage();
	}


	loadImage() {
		if(this.image) {
			this.image.on('load').then(this.onImageReady.bind(this));
		} else {
			setTimeout(this.triggerReady.bind(this), 400);
		}
	}

	triggerReady() {
		this.events.trigger(this.element, 'homeslide:imageready');
	}


	createVideo() {
		if(this.isVideoCreated) return;
		const options = {
			responsive: false,
			controls: false,
			background: true,
			loop: true,
			autoplay: false,
			autopause: true,
			playsinline: true,
			muted: true,
			dnt: true
		};
		options.url = this.dataAttr(this.videoElement).get(this.urlData) + '?dnt=1';
		if(options.url) {
			this.player = new Player(this.videoElement, options);
			this.playListener = this.player.on('play', this.onPlay.bind(this));
			this.timeUpdateListener = this.player.on('timeupdate', this.onTimeUpdate.bind(this));
		}
		this.isVideoCreated = true;
	}

	clear() {
		if(this.player) {
			this.player.off('play', this.onPlay.bind(this));
			this.player.off('timeupdate', this.onTimeUpdate.bind(this));
			this.player.unload();
		}
	}

	onPlay() {
		if(!this.isSlideActive) {
			this.pauseVideo();
		}
	}

	onTimeUpdate() {
		if(!this.isSlideActive) {
			this.pauseVideo();
		}
	}

	onImageReady() {
		if(this.initiated !== true) {
			this.initiated = true;
			this.triggerReady();
		}
	}

	playSlide(isFastAnimation) {
		this.isSlideActive = true;
		this.playVideo();
		this.clearSlideTimeout();
		this.timeoutId = setTimeout(this.setActive.bind(this), isFastAnimation?0:this.showContentDelay * 1000);
	}

	pauseSlide() {
		this.isSlideActive = false;
		this.pauseVideo();
		this.clearSlideTimeout();
		this.timeoutId = setTimeout(this.unsetActive.bind(this), this.hideContentDelay * 1000);
	}


	//stops on context change
	stop() {
		if(this.isSlideActive) this.pauseVideo();
	}

	//starts on context change
	start() {
		if(this.isSlideActive) this.playVideo();
	}

	playVideo() {
		if(!this.isSlideActive) return;
		this.createVideo();
		if(!this.player) return;
		this.player.play().then(() => {
			this.events.trigger(this.element, 'homeslide:videoplay');
		});
	}

	pauseVideo() {
		if(!this.player) return;
		this.player.getPaused().then((paused) => {
			if(!paused) {
				this.player.pause();
			}
		});
	}

	clearSlideTimeout() {
		if(this.timeoutId) clearTimeout(this.timeoutId);
	}

	setActive() {
		this.classList(this.element).add(this.activeClass);
	}

	unsetActive() {
		this.classList(this.element).remove(this.activeClass);
	}

	setHeight(height) {
		this.height = height;
		this.element.style.height = this.height + 'px';
	}

	getHeadline() {
		return this.dataAttr(this.element).get('headline');
	}
}

export default HomeSlide;
