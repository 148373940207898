import DlxSlideshowNavigation from './dlx-slideshow-navigation';
import DlxModalSlideshowNavigation from './dlx-modal-slideshow-navigation';


export default (di) => {
	di
		.setType({type: DlxSlideshowNavigation, name: 'DlxSlideshowNavigation', parent: 'SlideshowNavigation'})
		.setType({type: DlxModalSlideshowNavigation, name: 'DlxModalSlideshowNavigation', parent: 'SlideshowNavigation'})
	;
};
