import FilteredArticles from './filtered-articles';
import FilterCheckboxes from './filter-checkboxes';
//import FilterSearch from './filter-search';
//import FilteredArticlesInfoBox from './filtered-article-info-box';

export default (di) => {
	di
		.setType({type: FilteredArticles, name: 'FilteredArticles', parent: 'PageComponent'})
		.setType({type: FilterCheckboxes, name: 'FilterCheckboxes', parent: 'PageComponent'})
		//.setType({type: FilterSearch, name: 'FilterSearch', parent: 'PageComponent'})
		//.setType({type: FilteredArticlesInfoBox, name: 'FilteredArticlesInfoBox', parent: 'PageComponent'})
	;
};
