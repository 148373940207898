import CursorEffect from './cursor-effect';
import Loader from './loader';
import IdleTimer from './idle-timer';
import ParallaxElement from './parallax-element';
import AnimatedHeadline from './animated-headline';

export default (di) => {
	di
		.setType({type: CursorEffect, name: 'CursorEffect', parent: 'PageComponent', params: {
				mediaQuery: di.lazyValue('menuMediaQuery', 'fail')
			}
		})
		.setType({type: Loader, name: 'Loader', parent: 'PageComponent'})
		.setType({type: IdleTimer, name: 'IdleTimer', parent: 'PageComponent'})
		.setType({type: ParallaxElement, name: 'ParallaxElement', parent: 'PageComponent'})
		.setType({type: AnimatedHeadline, name: 'AnimatedHeadline', parent: 'PageComponent'})
		;
};
