import 'matchmedia-polyfill';
import ModalContext from './modal-context';

class MenuContext extends ModalContext {
		constructor({name, root, popOnKey = 'esc', fixedClass = 'fixed', activeClass = 'active', mediaQuery}) {
			super({name: name, root: root, popOnKey: popOnKey, fixedClass: fixedClass, activeClass: activeClass});
			this.mediaQuery = mediaQuery;
		}

		afterSwitchIn(otherContext) {
			this.resizeLisener = this.events.on(window, 'window:resize', this.onResize.bind(this));
			return otherContext;
		}

		beforeSwitchOut(otherContext) {
			this.resizeLisener.destroy();
			return otherContext;
		}

		checkMaxMenuWidth() {
			if (!matchMedia(this.mediaQuery).matches) {
				if (this.contexts.getCurrentContext().getName() === 'menu') {
					this.contexts.pop();
				}
			}
		}

		onResize(event) {
			this.checkMaxMenuWidth();
		}
}

export default MenuContext;
