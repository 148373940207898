import PageComponent from '../../common/component/page-component';

class InputFile extends PageComponent {
	constructor({root, element, inputAttribute = 'file-input', labelAttribute = 'file-label'}) {
		super({root: root, element: element});
		this.inputAttribute = inputAttribute;
		this.labelAttribute = labelAttribute;
	}

	prepare() {
		this.input = this.element.querySelector(this.dataSelector(this.inputAttribute));
		this.label = this.element.querySelector(this.dataSelector(this.labelAttribute));

		console.log(this.input);
		console.log(this.label);

		this.submit = this.events.on(this.input, 'change', this.onChange.bind(this));
	}

	onChange(event) {
		const fileName = this.input.files[0].name;
		this.label.textContent = fileName;
	}
}

export default InputFile;
