import AsyncPicture from './async-picture';
import ExternalVideo from './external-video';


const config = (di) => {
	di
		.setType({type: AsyncPicture, name: 'AsyncPicture', parent: 'PageComponent'})
		.setType({type: ExternalVideo, name: 'ExternalVideo', parent: 'PageComponent'})
	;
};


export default config;
