import HomeSlides from './home-slides';
import HomeSlide from './home-slide';
import HomeSlideSpecial from './home-slide-special';
import HomeSlidesNav from './home-slides-nav';
import HomeHeadline from './home-headline';
import HomeTimer from './home-timer';
import HomeParallaxElement from './home-parallax-element';

export default (di) => {
	di
		.setType({type: HomeSlides, name: 'HomeSlides', parent: 'PageComponent'})
		.setType({type: HomeSlide, name: 'HomeSlide', parent: 'PageComponent'})
		.setType({type: HomeSlideSpecial, name: 'HomeSlideSpecial', parent: 'PageComponent'})
		.setType({type: HomeSlidesNav, name: 'HomeSlidesNav', parent: 'PageComponent'})
		.setType({type: HomeHeadline, name: 'HomeHeadline', parent: 'PageComponent'})
		.setType({type: HomeTimer, name: 'HomeTimer', parent: 'PageComponent'})
		.setType({type: HomeParallaxElement, name: 'HomeParallaxElement', parent: 'PageComponent'})
	;
};
