import {ucFirst} from '../../common/utils/string';
import PageComponent from '../../common/component/page-component';


class FilteredArticles extends PageComponent {
	constructor({
					root,
					element,
					activeClass = 'active', //meets the filtering criteria
					articlesListAttribute = 'articlesList',
					articleAttribute = 'article',
					articleSearchAttribute = 'searchData',
					categoryAttribute = 'category',
					filterAttribute = 'filter',
					filterCheckboxAttribute = 'checkbox',
					filterSearchAttribute = 'filterSearch',
					maxInitialArticlesNo = 10,
					minSearchLength = 3,
					searchAttribute = 'search',
					showClass = 'show',
					showMoreAttribute = 'showmore',
					sortingTogglerAttribute = 'sortingToggler',
					sortingAttribute = 'sorting'
				}) {
		super({root: root, element: element});
		this.activeClass = activeClass;
		this.articlesListAttribute = articlesListAttribute;
		this.articleAttribute = articleAttribute;
		this.articleSearchAttribute = articleSearchAttribute;
		this.categoryAttribute = categoryAttribute;
		this.filterAttribute = filterAttribute;
		this.filterCheckboxAttribute = filterCheckboxAttribute;
		this.defaults.maxInitialArticlesNo = maxInitialArticlesNo;
		this.searchAttribute = searchAttribute;
		this.showClass = showClass;
		this.showMoreAttribute = showMoreAttribute;
		this.minSearchLength = minSearchLength;
		this.sortingTogglerAttribute = sortingTogglerAttribute;
		this.sortingAttribute = sortingAttribute;
	}

	prepare() {
		const data = this.dataAttr(this.element).getAll();
		this.maxInitialArticlesNo = data.maxInitialArticlesNo || this.defaults.maxInitialArticlesNo;
		this.filters = this.getComponents('FilterCheckboxes', this.element);
		this.filtersNo = this.filters.length;

		this.search = this.getComponent('FilterSearch', this.element);

		this.articlesList = this.element.querySelector(this.dataSelector(this.articlesListAttribute));

		this.articles = this.element.querySelectorAll(this.dataSelector(this.articleAttribute));
		this.articlesNo = this.articles.length;

		this.multiplier = 1;

		this.showMoreButton = this.element.querySelector(this.dataSelector(this.showMoreAttribute));
		this.listeners.showmore = this.events.on(this.showMoreButton, 'click', this.onShowMore.bind(this));

		this.listeners.filterchange = this.events.on(this.element, 'filter:change', this.onCheckboxChange.bind(this));
		this.listeners.searchchange = this.events.on(this.element, 'search:change', this.onSearchChange.bind(this));

		this.listeners.showmore = this.events.on(this.element, this.dataSelector(this.sortingTogglerAttribute), 'click', this.onSortingToggler.bind(this));

		this.onFiltersChange();

		this.sortingTogglers = this.element.querySelectorAll(this.dataSelector(this.sortingTogglerAttribute));

		if(this.sortingTogglers.length) {
			this.sort('alphabetical');
		}
	}

	onShowMore() {
		this.showMoreArticles();
	}

	onCheckboxChange() {
		this.onFiltersChange();
	}

	onSearchChange() {
		this.onFiltersChange();
	}

	onFiltersChange() {
		this.getFiltersData();
		this.hideAll();
		this.makeAllActive();
		this.filterArticles();

		this.currentArticles = this.element.querySelectorAll(this.dataSelector(this.articleAttribute) + this.classSelector(this.activeClass));
		this.currentArticlesNo = this.currentArticles.length;

		this.showMoreArticles();
		this.checkForShowAll();
	}

	getFiltersData() {
		const filterData = [];
		for (let i = 0; i < this.filtersNo; i++) {
			const filter = this.filters[i];
			if (filter.getHasCheckedElement() === true) {
				filterData.push({name: filter.getName(), data: filter.getData()});
			}
		}
		this.filterData = filterData;

		//no search
		//this.searchData = this.search.getData().toLowerCase();
	}

	hideAll() {
		for (let i = 0; i < this.articlesNo; i++) {
			this.classList(this.articles[i]).remove(this.showClass);
			this.currentShowArticleId = 0;
		}
	}

	filterArticles() {
		for (let i = 0; i < this.articlesNo; i++) {
			const article = this.articles[i];
			for (let j = 0; j < this.filterData.length; j++) {
				const filter = this.filterData[j];
				const filterName = filter.name;
				const filterValues = filter.data;
				const articleFilterData = this.dataAttr(article).get(this.filterAttribute + filterName);
				//const articleFilterDataArray = articleFilterData.split(' ');

				if (!articleFilterData || !filterValues.some(r => articleFilterData.indexOf(r) >= 0)) {
					this.classList(article).remove(this.activeClass);
				}
			}

			//if(!this.checkSearchBox(article)) {
			//	this.classList(article).remove(this.activeClass);
			//}
		}
	}

	checkSearchBox(article) {
		const contentString =  this.dataAttr(article).get(this.articleSearchAttribute).toLowerCase();

		if((this.searchData.length <= this.minSearchLength) || (this.searchData.length > this.minSearchLength && contentString.indexOf(this.searchData) > -1)) {
			return true;
		}

		return false;
	}

	makeAllActive() {
		for (let i = 0; i < this.articlesNo; i++) {
			this.classList(this.articles[i]).add(this.activeClass);
		}
	}

	makeAllInactive() {
		for (let i = 0; i < this.articlesNo; i++) {
			this.classList(this.articles[i]).remove(this.activeClass);
		}
	}

	checkForShowAll() {
		if (this.showMoreButton) {
			if (this.currentShowArticleId < this.currentArticlesNo) {
				this.showMoreButton.style.display = 'block';
			} else {
				this.showMoreButton.style.display = 'none';
			}
		}
	}

	showMoreArticles() {
		for (let i = this.currentShowArticleId; i < this.currentShowArticleId + this.maxInitialArticlesNo; i++) {
			const el = this.currentArticles[i];
			if (el) {
				this.classList(el).add(this.showClass);
			}
		}
		this.currentShowArticleId += this.maxInitialArticlesNo;

		this.checkForShowAll();
	}

	onSortingToggler(event) {
		const currentTarget = event.target;
		const sorting = this.dataAttr(currentTarget).get(this.sortingAttribute);

		this.sort(sorting);
	}

	sort(sorting) {
		const sortingType = 'sorting' + ucFirst(sorting);

		if(this.currentSorting === sorting) {
			this.multiplier *= -1;
		} else {
			this.multiplier = 1;
		}

		this.currentSorting = sorting;

		[...this.articles]
			.sort( (a, b) => (this.dataAttr(a).get(sortingType) > this.dataAttr(b).get(sortingType)?1 * this.multiplier:-1 * this.multiplier) )
			.forEach( node => this.articlesList.appendChild(node) );
	}
}

export default FilteredArticles;
