import Header from './header';
import MenuToggler from './menu-toggler';
import MainMenu from './main-menu';
import SearchToggler from './search-toggler';
import SlidingMenu from './sliding-menu';

export default (di) => {
	di
		.setType({type: Header, name: 'Header', parent: 'PageComponent'})
		.setType({type: MenuToggler, name: 'MenuToggler', parent: 'PageComponent', params: {
				mediaQuery: di.lazyValue('menuMediaQuery', 'fail')
			}
		})
		.setType({type: MainMenu, name: 'MainMenu', parent: 'PageComponent', params: {
				mediaQuery: di.lazyValue('menuMediaQuery', 'fail')
			}
		})
		.setType({type: SearchToggler, name: 'SearchToggler', parent: 'PageComponent', params: {
				mediaQuery: di.lazyValue('menuMediaQuery', 'fail')
			}
		})
		.setType({type: SlidingMenu, name: 'SlidingMenu', parent: 'PageComponent'})
	;
};
