import 'intersection-observer';
import svg4everybody from 'svg4everybody';
import picturefill from 'picturefill';
import App from '../../common/app/app';

class DlxApp extends App {
	injectCurrentLinkManager(currentLinkManager) {
		this.currentLinkManager = currentLinkManager;
	}


	injectNavigationTracker(tracker) {
		this.tracker = tracker;
	}


	injectHashScroller(hashScroller) {
		this.hashScroller = hashScroller;
	}


	injectPageSlots(pageSlots) {
		this.pageSlots = pageSlots;
	}


	injectPages(pages) {
		this.pages = pages;
	}


	init() {
		svg4everybody();
		picturefill();
		this.contexts.setDefaultContext('default', false);
		return super.init();
		// .then(() => {
		// 	this.loader = this.getComponent('appLoader');
		// });
	}


	execute() {
		this.pages.initCurrent()
			//.then(() => {
			//	return this.getComponent('Loader').playAnimation();
			//})
			.then(() => {
				this.classList(this.root).add('loaded');
				this.getComponent('Loader').removeAnimation();
				this.contexts.getContext('default').activate();
				// this.currentLinkManager.update();
				const hash = location.hash;
				if (hash.length) {
					this.events.trigger(document.body, 'history:hashchange', {hash: hash.substr(1)});
				}
			})
		;
	}
}

export default DlxApp;
