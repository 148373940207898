import DefaultPage from './default-page';
import HomePage from './home-page';
import DefaultPageTransition from './default-page-transition';


export default (di) => {
	di
		.setType({
			type: DefaultPage, name: 'DefaultPage', parent: 'Page', setters: {
				injectIntersectionTriggers: di.lazyNew('IntersectionTriggers'),
			}
		})
		.setType({type: HomePage, name: 'HomePage', parent: 'DefaultPage'})
		.setType({type: DefaultPageTransition, name: 'DefaultPageTransition', parent: 'PageTransition'})
	;
};
