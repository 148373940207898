import 'gsap/ScrollToPlugin';
import {TweenMax} from 'gsap';
import lottie from 'lottie-web';
import offsetTop from '../../common/utils/offset-top';
import PageComponent from '../../common/component/page-component';

class InteractiveGraphic extends PageComponent {
	constructor({
			root,
			element,
			activeClass = 'active',
			popupAttribute = 'popup',
			currentAttribute = 'current',
			navButtonData = 'navButton',
			navButtonIdData = 'item_id',
			closeData = 'close',
			lottieData = 'graphicLottie',
			graphicElementData = 'graphicElement',
			graphicElements = ['sls-1000', 'switch', 'antenna', 'apc-master',
				'ethernet-backbone', 'seat-management', 'wifi', 'citysense', 'board-computer', 'server'],
			interactiveElementData = 'interactiveSvgElement',
		}) {
		super({root: root, element: element});
		this.activeClass = activeClass;
		this.popupAttribute = popupAttribute;
		this.currentAttribute = currentAttribute;
		this.navButtonData = navButtonData;
		this.navButtonIdData = navButtonIdData;
		this.closeData = closeData;
		this.lottieData = lottieData;
		this.graphicElementData = graphicElementData;
		this.graphicElements = graphicElements;
	}

	prepare() {
		this.popup = this.root.querySelector(this.dataSelector(this.popupAttribute));
		this.graphic = this.element.querySelector(this.dataSelector(this.lottieData));

		this.SlidingGraphic = this.components.queryComponent(this.root, this.dataSelector('component', 'SlidingGraphic'));

		this.buildAnimation();

		this.listeners.buttonListener = this.events.on(this.element, this.dataSelector(this.navButtonData), 'click', this.onButton.bind(this));
		this.listeners.closeListener = this.events.on(this.element, this.dataSelector(this.closeData), 'click', this.onCloseButton.bind(this));

		this.elementsNo = this.graphicElements.length;

		for(let i=0; i<this.elementsNo; i++) {
			const overListener = this.events.on(document, '#'+this.graphicElements[i], 'mouseover click', this.onElementOver.bind(this));
			const outListener = this.events.on(document, '#'+this.graphicElements[i], 'mouseout', this.onElementOut.bind(this));
			const clickListener = this.events.on(document, '#'+this.graphicElements[i], 'click', this.onElementClick.bind(this));
			this.listeners['over'+i] = overListener;
			this.listeners['out'+i] = outListener;
			this.listeners['click'+i] = clickListener;
		}
	}

	onElementOver(e) {
		this.dataAttr(this.element).set(this.graphicElementData, e.target.closest('.interactive').id);
	}

	onElementClick(e) {
		this.showPopup(e.target.closest('.interactive').id);
	}

	onElementOut(e) {
		this.dataAttr(this.element).set(this.graphicElementData, '');
	}

	onButton(e) {
		const element = e.target.closest(this.dataSelector(this.navButtonData));
		this.showPopup(this.dataAttr(element).get(this.navButtonIdData));
	}

	onCloseButton(e) {
		this.hidePopup();
	}

	showPopup(id) {
		this.classList(this.popup).add(this.activeClass);

		this.item = this.popup.querySelector(this.dataSelector(this.navButtonIdData, id));

		if(this.currentEl) {
			this.classList(this.currentEl).remove(this.activeClass);
		}

		if(this.item) {
			this.classList(this.item).add(this.activeClass);
			this.currentEl = this.item;
		}

		this.dataAttr(this.element).set(this.currentAttribute, id);

		TweenMax.to(window, 0.5, {scrollTo: {y: offsetTop(this.element), autoKill: true}}, 20);
	}

	hidePopup() {
		this.classList(this.popup).remove(this.activeClass);
		this.dataAttr(this.element).remove(this.currentAttribute);
	}

	buildAnimation() {
		this.animation = lottie.loadAnimation({
			container: this.graphic,
			renderer: 'svg',
			loop: true,
			autoplay: false,
			path: '/assets/json/interactivegraphic-test.json'
		});

		this.listeners.animListener = this.animation.addEventListener('DOMLoaded', this.onDomLoaded.bind(this));
		this.animation.play();
	}

	onDomLoaded() {
		this.SlidingGraphic.onResize();
	}
}

export default InteractiveGraphic;
