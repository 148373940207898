import {TweenMax} from 'gsap';
import {getViewportHeight} from '../../common/utils/size';
import PageComponent from '../../common/component/page-component';

class ParallaxElement extends PageComponent {
	constructor({
		root,
		element,
		duration = 0.5,
		speeds = {fast: 0.5, mid: 0.4, slow: 0.2, superslow: 0.05},
	}) {
		super({root: root, element: element});
		this.speeds = speeds;
		this.duration = duration;
	}

	prepare() {
		const speedKey = this.dataAttr(this.element).get('speed');
		if (isNaN(speedKey)) {
			this.speed = this.speeds[speedKey];
		} else {
			this.speed =  speedKey;
		}

		this.listeners.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.listeners.scrollListener = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
		this.update(false);
	}


	onScroll(event) {
	 this.update(true);
	}


	onResize(event) {
		this.update(false);
	}

	update(animate = true) {
		const rect = this.element.getBoundingClientRect();
		const viewportHeight = getViewportHeight();

		const isInView = rect.bottom > 0 && rect.top - viewportHeight < 0;
		//if element wasn't in the view previous frame, and now it is, skip the anim
		if(!this.isInView && isInView) animate = false;
		this.isInView = isInView;

		if(isInView || !animate) {
			const diff = this.element.getBoundingClientRect().top;
			let y = diff * this.speed;
			if (y === -0) {
				y = 0;
			}

			TweenMax.killTweensOf(this.element);
			TweenMax.to(this.element, animate?this.duration:0, {x: y + 'px', ease: 'Power1.easeOut', force3D: true});
		}
	}

	destroy() {
		TweenMax.killTweensOf(this.element);
	}
}


export default ParallaxElement;
