import PageComponent from '../../common/component/page-component';

class FilterCheckboxes extends PageComponent {
	constructor({
					root,
					element,
					categoryAttribute = 'category',
					checkboxAttribute = 'checkbox',
					filterCategoryAttribute = 'filterCategory'
				}) {
		super({root: root, element: element});

		this.categoryAttribute = categoryAttribute;
		this.checkboxAttribute = checkboxAttribute;
		this.filterCategoryAttribute = filterCategoryAttribute;
	}

	prepare() {
		this.hasCheckedElement = false;

		this.filterName = this.dataAttr().get(this.filterCategoryAttribute);

		this.checkboxes = this.element.querySelectorAll(this.dataSelector(this.checkboxAttribute));
		this.checkboxesNo = this.checkboxes.length;

		this.listeners.checkboxChange = this.events.on(this.element, this.dataSelector(this.checkboxAttribute), 'change', this.onCheckboxChange.bind(this));
	}

	onCheckboxChange(e) {
		this.checkHasCheckedElement();
		this.emitChange();
	}

	getData() {
		const data = [];
		for (let i = 0; i < this.checkboxesNo; i++) {
			if (this.checkboxes[i].checked === true) {
				data.push(this.checkboxes[i].value);
			}
		}
		return data;
	}

	checkHasCheckedElement() {
		let checkedElement = false;
		for (let i = 0; i < this.checkboxesNo; i++) {
			if (this.checkboxes[i].checked === true) {
				checkedElement = true;
				break;
			}
		}
		this.hasCheckedElement = checkedElement;
	}

	getHasCheckedElement() {
		return this.hasCheckedElement;
	}

	emitChange() {
		this.events.trigger(this.element, 'filter:change');
	}

	getName() {
		return this.filterName;
	}
}

export default FilterCheckboxes;
