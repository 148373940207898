import {TweenMax} from 'gsap';
import PageComponent from '../../common/component/page-component';

class CursorEffect extends PageComponent {
	constructor({
		root,
		element,
		mediaQuery,
		activeClass = 'active',
		cursorEffectElementData = 'cursorEffectElement',
		duration = 0.3
	}) {
		super({root: root, element: element});
		this.mediaQuery = mediaQuery;
		this.activeClass = activeClass;
		this.cursorEffectElementData = cursorEffectElementData;
		this.duration = duration;
	}

	prepare() {
		this.current = null;
		this.isActive = false;

		this.isMenuInDesktopView = true;
		this.checkMaxMenuWidth();

		this.listeners.show = this.events.on(document.body,
			this.dataSelector(this.cursorEffectElementData), 'mouseenter',
			this.onShowCursor.bind(this), {capture: true});

		this.listeners.hide = this.events.on(document.body,
			this.dataSelector(this.cursorEffectElementData), 'mouseleave',
			this.onHideCursor.bind(this), {capture: true});

		this.listeners.move = this.events.on(document, 'mousemove',
			this.onMouseMove.bind(this));

		this.listeners.resizeLisener = this.events.on(window, 'window:resize', this.onResize.bind(this));

		this.listeners.pagechange = this.events.on(window, 'history:pagechange', this.onPageChange.bind(this));
	}

	onResize(event) {
		this.checkMaxMenuWidth();
	}

	checkMaxMenuWidth() {
		if (!matchMedia(this.mediaQuery).matches) {
			this.isMenuInDesktopView = true;
		} else {
			this.isMenuInDesktopView = false;
		}
	}

	onPageChange() {
		this.onHideCursor();
	}

	onShowCursor(event) {
		if(this.currentElem) return;
		const target = event.target.closest(this.dataSelector(this.cursorEffectElementData));
		if(!target) return;
		this.currentElem = target;
		this.isActive = true;
		this.classList(this.element).add(this.activeClass);
		this.gotoCurrentPosition(false);
	}

	onHideCursor(event) {
		if(!this.currentElem) return;
	  let relatedTarget = event.relatedTarget;
	  while (relatedTarget) {
			if(relatedTarget === this.currentElem) return;
			relatedTarget = relatedTarget.parentNode;
	  }
	  this.currentElem = null;
		this.isActive = true;

		this.classList(this.element).remove(this.activeClass);
	}


	onMouseMove(event) {
		if(!this.isActive || !this.isMenuInDesktopView) return;
		this.gotoCurrentPosition();
	}

	gotoCurrentPosition(isAnimated = true) {
		const relX = event.clientX;
		const relY = event.clientY;
		TweenMax.to(this.element, isAnimated?this.duration:0, {x: relX, y: relY});
	}
}

export default CursorEffect;
