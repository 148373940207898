import Page from '../../common/page/page';


class DefaultPage extends Page {

		injectIntersectionTriggers(intersectionTriggers) {
			this.intersectionTriggers = intersectionTriggers;
		}

		onLoad() {
			this.intersectionTriggers.init(this.element, [this.dataSelector('intersect')]);
			return Promise.resolve();
		}
}


export default DefaultPage;
