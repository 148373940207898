import PageComponent from '../../common/component/page-component';

class FootNotes extends PageComponent {
	constructor({
		root,
		element,
		activeClass = 'active',
		footnoteActiveClass = 'footnoteActive',
		hover = true,
		linkSelector = 'footnoteLink',
		footnoteSelector = 'footnote',
		footnoteContentSelector = 'footnoteContent',
		margin = 0
	}) {
		super({root: root, element: element});
		this.activeClass = activeClass;
		this.footnoteActiveClass = footnoteActiveClass;
		this.hover = hover;
		this.linkSelector = linkSelector;
		this.footnoteSelector = footnoteSelector;
		this.footnoteContentSelector = footnoteContentSelector;
		this.margin = margin;
	}

	prepare() {
		this.current = null;
		const events = this.hover ? 'mouseover touchend' : 'click';
		this.listeners.openListener = this.events.on(this.element, this.dataSelector(this.linkSelector), events, this.onOver.bind(this), {passive: true});
		this.listeners.closeListener = this.events.on(document, 'click', this.onClose.bind(this));
		this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this));
	}

	onOver(event, target) {
		target = target.closest(this.dataSelector(this.linkSelector));
		if (target !== this.current) {
			if (this.current) {
			    this.classList(this.current).remove(this.activeClass);
			}
			this.classList(this.element).add(this.footnoteActiveClass);
			this.current = target;
			this.classList(this.current).add(this.activeClass);
			this.checkPosition();
		}
		if (!this.hover) {
			event.stopImmediatePropagation();
			event.stopPropagation();
		}
	}


	onClose(event, target) {
		if (this.current && !event.target.closest(this.dataSelector(this.linkSelector))) {
			this.classList(this.current).remove(this.activeClass);
			this.classList(this.element).remove(this.footnoteActiveClass);
			this.current = null;
		}
	}

	onResize() {
		this.checkPosition();
	}

	checkPosition() {
		if(this.current) {
			const footnote = this.current.querySelector(this.dataSelector(this.footnoteSelector));
			const footnoteContent = this.current.querySelector(this.dataSelector(this.footnoteContentSelector));
			footnoteContent.style.transform = 'translate(0, 0)';
			const elementRect = footnote.getBoundingClientRect();
			let setPosX = 0;
			const clientWidth = document.documentElement.clientWidth;
			if(elementRect.left + elementRect.width > clientWidth) {
				setPosX = clientWidth - ( elementRect.left + elementRect.width) - this.margin;
				footnoteContent.style.transform = 'translate(' + setPosX  + 'px, 0)';
			} else if(elementRect.left < 0) {
				setPosX = Math.abs(elementRect.left) + this.margin;
				footnoteContent.style.transform = 'translate(' + setPosX  + 'px, 0)';
			}
		}
	}
}

export default FootNotes;
