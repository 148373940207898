import PageTransition from '../../common/page/page-transition';


class DefaultPageTransition extends PageTransition {

		beginAnimation(request) {
			this.components.getComponent(document, 'Loader').playAnimation();
			return Promise.resolve(request);
		}


		endAnimation(request, response) {
			this.components.getComponent(document, 'Loader').removeAnimation();
			return Promise.resolve(response);
		}
}


export default DefaultPageTransition;
