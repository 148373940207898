/*
	This module does not do anything on mobile and old browsers
*/
import {getScrollTop} from '../../common/utils/get-scroll';
import PageComponent from '../../common/component/page-component';

class CaseStudy extends PageComponent {
	constructor({
		root,
		element,
		imageClipAttribute = 'imageClip',
		imagesContainerAttribute = 'imagesContainer',
		expandedClass = 'expanded',
		frontClass = 'front',
		fallbackInterval = 2, // sec
		delay = 0.5 // sec
	}) {
		super({root: root, element: element});
		this.imageClipAttribute = imageClipAttribute;
		this.imagesContainerAttribute = imagesContainerAttribute;
		this.expandedClass = expandedClass;
		this.frontClass = frontClass;
		this.fallbackInterval = fallbackInterval;
		this.delay = delay;

		this.expanded = false;
		this.mousePosition = {x: 0, y: 0};
		this.enabled = true;
	}

	prepare() {
		// if is not a mobile and supports clip-path (no old Edge nor IE)
		if (!document.querySelector('html.mobile') && 'CSS' in window && CSS.supports && (CSS.supports('clip-path: inset(0 0 0 0)') || CSS.supports('-webkit-clip-path: inset(0 0 0 0)'))) {
			this.imageClip = this.element.querySelector(this.dataSelector(this.imageClipAttribute));
			this.imagesContainer = this.element.querySelector(this.dataSelector(this.imagesContainerAttribute));
			this.listeners.resize = this.events.on(this.window, 'window:resize', this.onResize.bind(this));
			this.listeners.move = this.events.on(this.root, 'mousemove', this.onMouseMove.bind(this));
			this.listeners.enter = this.events.on(this.imageClip, 'mouseenter mouseover', this.onEnter.bind(this));
			this.listeners.leave = this.events.on(this.imageClip, 'mouseleave mouseout', this.onLeave.bind(this));
			this.updateGeometry();
			// works as a fallback, in case some late image load is pushing the elements down
			this.geometryInterval = setInterval(() => this.updateGeometry(), this.fallbackInterval * 1000);
			this.raf = requestAnimationFrame(this.update.bind(this));
		}
	}

	clear() {
		this.enabled = false;
		cancelAnimationFrame(this.raf);
		clearInterval(this.geometryInterval);
	}


	onEnter(event) {
		this.toggleExpanded(true);
	}


	onLeave(event) {
		this.toggleExpanded(false);
	}

	onMouseMove(event) {
		this.mousePosition.x = event.clientX;
		this.mousePosition.y = event.clientY;
	}


	onResize(event) {
		this.updateGeometry();
	}


	updateGeometry() {
		if (this.imageClip) {
			const rect = this.imageClip.getBoundingClientRect();
			const top = getScrollTop();
			this.clipRect = {
				left: rect.left,
				right: rect.right,
				top: rect.top + top,
				bottom: rect.bottom + top
			};
		}
	}


	update() {
		this.toggleExpanded(this.isIntersecting(getScrollTop()));
		if (this.enabled) {
			this.raf = requestAnimationFrame(this.update.bind(this));
		}
	}


	isIntersecting(scrollTop) {
		return (
			this.clipRect &&
			this.mousePosition.x >= this.clipRect.left &&
			this.mousePosition.x <= this.clipRect.right &&
			this.mousePosition.y >= (this.clipRect.top - scrollTop) &&
			this.mousePosition.y <= (this.clipRect.bottom - scrollTop)
		);
	}


	toggleExpanded(value) {
		const apply = (val) => {
			this.classList().toggle(this.expandedClass, val);
			if (val) {
				this.classList().add(this.frontClass);
			} else {
				this.onTransitionEnd(this.imagesContainer).then(() => {
					if (!this.expanded) {
						this.classList().remove(this.frontClass);
					}
				});
			}
		};

		value = !!value;
		if (value !== this.expanded) {
			this.expanded = value;
			if (value) {
				setTimeout(() => {
					if (this.expanded) {
						apply(true);
					}
				}, this.delay * 1000);
			} else {
				apply(false);
			}
		}
	}
}

export default CaseStudy;
