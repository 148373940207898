import PageComponent from '../../common/component/page-component';

class Glossary extends PageComponent {
	constructor({
		root,
		element,
		showLetterClass = 'showLetter',
		letterData = 'letter',
		glossaryItem = 'glossaryItem'
	}) {
		super({root: root, element: element});
		this.showLetterClass = showLetterClass;
		this.letterData = letterData;
		this.glossaryItem = glossaryItem;
	}

	prepare() {
		this.showIndexLetters();
	}

	showIndexLetters() {
		this.articles = this.element.querySelectorAll(this.dataSelector(this.glossaryItem));
		this.articlesNo = this.articles.length;

		let currentLetter = null;
		for (let i = 0; i < this.articlesNo; i++) {
			const article = this.articles[i];
			const letter = this.dataAttr(article).get(this.letterData);
			if (letter !== currentLetter) {
				this.classList(article).add(this.showLetterClass);
			} else {
				this.classList(article).remove(this.showLetterClass);
			}
			currentLetter = letter;
		}
	}
}

export default Glossary;
