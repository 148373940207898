import {TweenMax} from 'gsap';
import offsetTop from '../../common/utils/offset-top';
import {getViewportHeight} from '../../common/utils/size';
import PageComponent from '../../common/component/page-component';

class HomeParallaxElement extends PageComponent {
	constructor({
		root,
		element,
		speeds = {fast: 0.5, mid: 0.4, slow: 0.2, superslow: 0.1},
	}) {
		super({root: root, element: element});
		this.speeds = speeds;
	}

	prepare() {
		const speedKey = this.dataAttr(this.element).get('speed');
		if (isNaN(speedKey)) {
			this.speed = this.speeds[speedKey];
		} else {
			this.speed =  speedKey;
		}

		this.listeners.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));
	}


	onResize(event) {
		const viewportHeight = getViewportHeight();
		if (viewportHeight !== this.viewportHeight) {
			this.updateSizes(viewportHeight);
			this.update(true);
		}
	}


	updateSizes(viewportHeight = false) {
		this.viewportHeight = viewportHeight === false ? getViewportHeight() : viewportHeight;
		this.top =  offsetTop(this.element) - this.element.offsetTop;
	}


	update(animate = true) {
		const parentTop = this.parentElement.getBoundingClientRect().top;

		const diff = -parentTop - this.top;
		let y = -diff * this.speed;
		if (y === -0) {
			y = 0;
		}

		this.element.style.transform = 'translate3d(0,'+y+'px,0)';
	}


	setParentElement(parentElement) {
		this.parentElement = parentElement;
	}


	clear() {
		TweenMax.killTweensOf(this.element);
	}
}


export default HomeParallaxElement;
