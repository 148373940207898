import PageComponent from '../../common/component/page-component';

class IdleTimer extends PageComponent {
	constructor({
		root,
		element,
		idleClass = 'idle',
		duration = 4
	}) {
		super({root: root, element: element});
		this.element = element;
		this.duration = duration;
		this.idleClass = idleClass;
	}

	prepare() {
		this.timeoutId = null;

		this.listeners.action = this.events.on(document.body, 'mousedown mousemove wheel touchstart touchmove keydown scroll resize',
			this.onAction.bind(this));
	}

	//initiated from home-slides.js
	initiate() {
		this.setTimer();
	}

	destroy() {
		super.destroy();
		this.unsetClass();
		this.stopTimer();
	}

	stopTimer() {
		if(this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
	}

	onAction(e) {
		this.resetTimer();
	}

	setTimer() {
		this.stopTimer();
		this.timeoutId = setTimeout(this.setClass.bind(this), this.duration * 1000);
	}

	resetTimer() {
		this.unsetClass();
		this.setTimer();
	}

	setClass() {
		this.classList(document.body).add(this.idleClass);
	}

	unsetClass() {
		this.classList(document.body).remove(this.idleClass);
	}
}

export default IdleTimer;
