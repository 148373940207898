import PageComponent from '../../common/component/page-component';

class HomeSlidesNav extends PageComponent {
	constructor({
		root,
		element,
		activeClass = 'active',
		navButtonData = 'navButton',
		navButtonIdData = 'navButtonId',
	}) {
		super({root: root, element: element});
		this.activeClass = activeClass;
		this.navButtonData = navButtonData;
		this.navButtonIdData = navButtonIdData;
	}

	prepare() {
		this.currentButton = null;
		this.listeners.buttonListener = this.events.on(this.element, this.dataSelector(this.navButtonData), 'click', this.onButton.bind(this));
	}

	onButton(e) {
		const element = e.target.closest(this.dataSelector(this.navButtonData));
		this.emitChange(this.dataAttr(element).get(this.navButtonIdData));
	}


	emitChange(id) {
		this.events.trigger(this.element, 'homeslidesnav:change', {id: id});
	}

	setButton(id) {
		this.unsetButton();
		this.currentButton = this.element.querySelector(this.dataSelector(this.navButtonIdData, id));
		this.classList(this.currentButton).add(this.activeClass);
	}

	unsetButton() {
		if(this.currentButton) {
			this.classList(this.currentButton).remove(this.activeClass);
			this.currentButton = null;
		}
	}
}

export default HomeSlidesNav;
