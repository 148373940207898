/* simpified version of homepage homeHeadline
	should have the same timing values
*/


import PageComponent from '../../common/component/page-component';

class AnimatedHeadline extends PageComponent {
	constructor({
		root,
		element,
		animateClass = 'animate',
		animTime = 0.5, //same as scss $durationHomeHeadline
		wordDelayTime = 0.05, //same as scss $durationHomeHeadline
		delay = 1,
		downClass = 'down',
		wordTextData = 'wordText',
		hideClass = 'hide',
		minSameElements = 3,
		textData = 'text',
		contentData = 'content',
		upClass = 'up'
	}) {
		super({root: root, element: element});
		this.animateClass = animateClass;
		this.animTime = animTime;
		this.wordDelayTime = wordDelayTime;
		this.delay = delay;
		this.wordTextData = wordTextData;
		this.downClass = downClass;
		this.hideClass = hideClass;
		this.minSameElements = minSameElements;
		this.textData = textData;
		this.upClass = upClass;
		this.contentData = contentData;
	}

	prepare() {
		this.textElement =  this.element;
		this.textArr = this.dataAttr(this.textElement).get(this.textData).split(' ');
		requestAnimationFrame(this.showAnimation.bind(this));
	}

	showAnimation() {
		this.removeAnimationClass();
		this.htmlText = '';
		let delayId = 1;
		for(let i=0; i<this.textArr.length; i++) {
			this.htmlText += '<span class="dlx-animatedHeadline__word dlx-animatedHeadline__word--anim dlx-animatedHeadline__word--delay'
				+ (delayId++) + '"><span class="dlx-animatedHeadline__wordText">' + this.textArr[i] + '</span></span> ';
		}
		this.textElement.innerHTML = this.htmlText;
		setTimeout(this.addAnimationClass.bind(this), 100);
	}

	addAnimationClass() {
		this.classList(this.element).add(this.animateClass);
	}

	removeAnimationClass() {
		this.classList(this.element).remove(this.animateClass);
	}
}

export default AnimatedHeadline;
