import PageComponent from '../../common/component/page-component';

class HomeSlideSpecial extends PageComponent {
	constructor({
		root,
		element,
		activeClass = 'active',
		showContentDelay = 0.8,
		hideContentDelay = 0.2,
		margin = 80
	}) {
		super({root: root, element: element});
		this.activeClass = activeClass;
		this.showContentDelay = showContentDelay;
		this.hideContentDelay = hideContentDelay;
		this.margin = margin;
	}

	prepare() {
		this.initiated = false;
		this.isSlideActive = false;
	}

	initialize() {
		this.initTimeoutId = setTimeout(this.triggerReady.bind(this), 1000);
	}

	triggerReady() {
		if(this.initTimeoutId) clearTimeout(this.initTimeoutId);
		//two triggers needed to initialize auto change slider.
		this.events.trigger(this.element, 'homeslide:imageready');
		this.events.trigger(this.element, 'homeslide:videoplay');
	}

	playSlide(isFastAnimation) {
		this.isSlideActive = true;
		this.clearSlideTimeout();
		this.timeoutId = setTimeout(this.setActive.bind(this), isFastAnimation?0:this.showContentDelay * 1000);
	}

	pauseSlide() {
		this.isSlideActive = false;
		this.clearSlideTimeout();
		this.timeoutId = setTimeout(this.unsetActive.bind(this), this.hideContentDelay * 1000);
	}

	//stops on context change
	stop() {
		//
	}

	//starts on context change
	start() {
		//
	}

	clearSlideTimeout() {
		if(this.timeoutId) clearTimeout(this.timeoutId);
	}

	setActive() {
		this.classList(this.element).add(this.activeClass);
	}

	unsetActive() {
		this.classList(this.element).remove(this.activeClass);
	}

	setHeight(height) {
		this.height = height;
		this.element.style.height = (this.height - this.margin) + 'px';
	}

	getHeadline() {
		return this.dataAttr(this.element).get('headline');
	}
}

export default HomeSlideSpecial;
