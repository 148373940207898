import Impetus from 'impetus';
import PageComponent from '../../common/component/page-component';

class SlidingMenu extends PageComponent {
	constructor({
			root,
			element,
		}) {
		super({root: root, element: element});
	}

	prepare() {
		this.impetus = new Impetus({
				source: this.element,
				boundX: [0, 0], // width of content - width of mask => 1310 - 400
				initialValues: [0, 0],
				update: (x, y) => {
					this.element.style.transform = 'translate(' + x + 'px, 0)';
				}
		});

		this.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.onResize();
  }

	onResize() {
		const diff = this.element.offsetWidth-this.element.scrollWidth;
		if(diff < 0) {
			this.impetus.resume();
			this.impetus.setBoundX([diff, 0]);
		} else {
			this.reset();
			this.impetus.pause();
		}
	}

	reset() {
		this.element.style.transform = 'translate(' + 0 + ', 0)';
	}
}

export default SlidingMenu;
