import {TweenMax} from 'gsap';
import 'gsap/ScrollToPlugin';
import domMixin from '../dom/dom-mixin';
import offsetTop from '../utils/offset-top';


class HashScroller extends domMixin() {

    constructor({events, duration = 2}) {
		super();
		this.events = events;
		this.duration = duration;
		this.events.on(document, 'history:hashchange', this.onHashChange.bind(this));
    }


	onHashChange(event) {
		const hash = event.detail.hash;
		if (hash !== null) {
			let element = document.querySelector('#' + hash);
			if (!element) {
				element = document.querySelector(this.dataSelector('hash', hash));
			}

			if (element) {
				requestAnimationFrame(() => {
					//leave margin on top for the header
					const position = offsetTop(element) - 140;
					TweenMax.to(window, this.duration, {scrollTo: {y: position, autoKill: false}}, 200);
				});
			}
		}
	}
}

export default HashScroller;
