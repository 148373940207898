import PageComponent from '../../common/component/page-component';

class HomeHeadline extends PageComponent {
	constructor({
		root,
		element,
		animateClass = 'animate',
		animTime = 0.5, //same as scss $durationHomeHeadline
		wordDelayTime = 0.05, //same as scss $durationHomeHeadline
		delay = 1,
		downClass = 'down',
		wordTextData = 'wordText',
		hideClass = 'hide',
		minSameElements = 3,
		textData = 'headlineText',
		upClass = 'up'
	}) {
		super({root: root, element: element});
		this.animateClass = animateClass;
		this.animTime = animTime;
		this.wordDelayTime = wordDelayTime;
		this.delay = delay;
		this.wordTextData = wordTextData;
		this.downClass = downClass;
		this.hideClass = hideClass;
		this.minSameElements = minSameElements;
		this.textData = textData;
		this.upClass = upClass;
	}

	prepare() {
		this.prevTextArr = [];
		this.textArr = [];
		this.textElement =  this.element.querySelector(this.dataSelector(this.textData));
	}


	setText(text, direction) {
		if(this.currentText === text) return;

		this.direction = direction;
		this.setDirectionClass();
		this.removeHideClass();

		this.prevTextArr = this.textArr;
		this.textArr = text.split(' ');

		if(this.currentText === null) {
			this.currentText = text;
			requestAnimationFrame(this.showAnimation.bind(this));
		}	else {
			this.currentText = text;
			requestAnimationFrame(this.hideAnimation.bind(this));
		}
	}


	hideAnimation() {
		this.htmlText = '';
		let delayId = 1;
		const sameElementsNo = this.checkSameElements(this.prevTextArr, this.textArr);
		for(let i=0; i<this.prevTextArr.length; i++) {
			if(i<sameElementsNo) {
				this.htmlText += '<span class="dlx-homeHeadline__word">'+this.prevTextArr[i]+'</span> ';
			} else {
				this.htmlText += '<span class="dlx-homeHeadline__word dlx-homeHeadline__word--anim dlx-homeHeadline__word--delay'
					+ (delayId++) + '"><span data-dlx-word-text class="dlx-homeHeadline__wordText">' + this.prevTextArr[i] + '</span></span> ';
			}
		}
		this.textElement.innerHTML = this.htmlText;

		requestAnimationFrame(this.addHideClass.bind(this));

		const wordTexts = this.element.querySelectorAll(this.dataSelector(this.wordTextData));
		const lastWordText = wordTexts[wordTexts.length - 1];

		if(lastWordText) {
			requestAnimationFrame(() => {this.onTransitionEnd(lastWordText).then(this.showAnimation.bind(this));});
		} else {
			this.clearTextTimeout();
			requestAnimationFrame(() => {this.textTimeoutId = setTimeout(this.showAnimation.bind(this), 100);});
		}
	}


	showAnimation() {
		this.removeAnimationClass();
		this.removeHideClass();
		this.htmlText = '';
		let delayId = 1;
		const sameElementsNo = this.checkSameElements(this.prevTextArr, this.textArr);
		for(let i=0; i<this.textArr.length; i++) {
			if(i<sameElementsNo) {
				this.htmlText += '<span class="dlx-homeHeadline__word">'+this.textArr[i]+'</span> ';
			} else {
				this.htmlText += '<span class="dlx-homeHeadline__word dlx-homeHeadline__word--anim dlx-homeHeadline__word--delay'
					+ (delayId++) + '"><span class="dlx-homeHeadline__wordText">' + this.textArr[i] + '</span></span> ';
			}
		}
		this.textElement.innerHTML = this.htmlText;

		if(this.currentText.length < 3) {
			this.element.style.display = 'none';
		} else {
			this.element.style.display = 'block';
		}

		this.clearTextTimeout();
		this.textTimeoutId = setTimeout(this.addAnimationClass.bind(this), 100);
	}


	checkSameElements(arr1, arr2) {
		let sameElementsCounter = 0;
		for(let i=0; i<Math.min(arr1.length, arr2.length); i++) {
			if(arr1[i] === arr2[i]) {
				sameElementsCounter++;
			}
		}
		return sameElementsCounter>this.minSameElements?sameElementsCounter:0;
	}

	setDirectionClass() {
		if(this.direction === 'up') {
			this.classList(this.element).add(this.upClass);
			this.classList(this.element).remove(this.downClass);
		} else {
			this.classList(this.element).add(this.downClass);
				this.classList(this.element).remove(this.upClass);
		}
	}

	clearTextTimeout() {
		if(this.textTimeoutId) clearTimeout(this.textTimeoutId);
		this.textTimeoutId = null;
	}

	addHideClass() {
		this.classList(this.element).add(this.hideClass);
	}

	removeHideClass() {
		this.classList(this.element).remove(this.hideClass);
	}

	addAnimationClass() {
		this.removeHideClass();
		this.classList(this.element).add(this.animateClass);
	}

	removeAnimationClass() {
		this.classList(this.element).remove(this.animateClass);
	}
}

export default HomeHeadline;
