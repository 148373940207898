import {TweenMax} from 'gsap';
import PageComponent from '../../common/component/page-component';

class HomeTimer extends PageComponent {
	constructor({
		root,
		element,
		stripData = 'timerStrip',
		hiddenClass = 'hidden',
		changeTime = 21,
		moveBackTime = 1.1
	}) {
		super({root: root, element: element});
		this.stripData = stripData;
		this.hiddenClass = hiddenClass;
		this.changeTime = changeTime;
		this.moveBackTime = moveBackTime;
	}

	prepare() {
		this.isStopped = false;
		this.stripElement = this.element.querySelector(this.dataSelector(this.stripData));
	}

	destroy() {
		this.stopTimer();
	}

	stopTimer() {
		this.classList(this.element).add(this.hiddenClass);
		TweenMax.killTweensOf(this.stripElement);
		this.isStopped = true;
	}

	startTimer() {
		if(this.isStopped) return;
		TweenMax.to(this.stripElement, 0, {width: '0%'});
		TweenMax.to(this.stripElement, this.changeTime, {width: '100%', ease: 'Power0.easeNone', onComplete: this.onTimerComplete.bind(this)});
	}

	restart() {
		if(this.isStopped) return;
		TweenMax.to(this.stripElement, this.moveBackTime, {width: '0%'});
		TweenMax.to(this.stripElement, this.changeTime, {width: '100%', ease: 'Power0.easeNone', delay: this.moveBackTime, onComplete: this.onTimerComplete.bind(this)});
	}

	onTimerComplete() {
		this.events.trigger(this.element, 'hometimer:complete');
	}
}

export default HomeTimer;
