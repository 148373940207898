import {getScrollTop} from 'get-scroll';
import PageComponent from '../../common/component/page-component';

class Header extends PageComponent {
	constructor({
		root,
		element,
		scrolledDownClass = 'scrolledDown',
		scrollMargin = 10
	}) {
		super({root: root, element: element});
		this.scrolledDownClass = scrolledDownClass;
		this.scrollMargin = scrollMargin;
	}

	prepare() {
		this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
	}

	onScroll() {
		this.checkScroll();
	}

	checkScroll() {
		const scrollTop = getScrollTop();
		if(scrollTop > this.scrollMargin) {
			this.classList(this.element).add(this.scrolledDownClass);
			this.classList(document.body).add(this.scrolledDownClass);
		} else {
			this.classList(this.element).remove(this.scrolledDownClass);
			this.classList(document.body).remove(this.scrolledDownClass);
		}
	}
}

export default Header;
