import keymaster from 'keymaster';
import PageComponent from '../../common/component/page-component';


class DlxModalSlideshowNavigation extends PageComponent {

	constructor({
		root,
		element,
		navigationAttribute = 'goTo',
		modalContentAttribute = 'modalContent',
		currentClass = 'current',
		nextClass = 'next',
		activeClass = 'active',
		modalContextName = 'modalGallery',
	}) {
		super({root: root, element: element});
		this.navigationAttribute =  navigationAttribute;
		this.currentClass = currentClass;
		this.nextClass = nextClass;
		this.activeClass = activeClass;
		this.modalContextName = modalContextName;
	}


	prepare() {
		this.listeners.swipe = this.events.on(this.element.parentNode, 'swipe', this.onSwipe.bind(this));
		this.listeners.click = this.events.on(this.element, this.dataSelector(this.navigationAttribute), 'click', this.onClick.bind(this));
		keymaster('left, right', 'modalGallery', this.onDirectionPress.bind(this));
	}


	getCurrentSlideshow() {
		return this.components.queryComponent(this.element.parentNode, this.classSelector(this.activeClass) + this.dataSelector('component', 'Slideshow'));
	}


	onClick(event, target) {
		event.preventDefault();
		this.navigate(this.dataAttr(target).get(this.navigationAttribute));
	}


	onDirectionPress(event, handler) {
		event.preventDefault();
		const dir = (handler.shortcut === 'left' ? 'prev' : 'next');
		this.navigate(dir);
	}


	onSwipe(event) {
		const dir = (event.gesture.offsetDirection === this.events.gestures.DIRECTION_RIGHT ? 'prev' : 'next');
		this.navigate(dir);
	}


	navigate(index) {
		const slideshow = this.getCurrentSlideshow();
		if (slideshow) {
			switch (index) {
				case 'next':
					slideshow.next();
					break;
				case 'prev':
					slideshow.prev();
					break;
				default:
					slideshow.goTo(index);
			}
		}
	}

}

export default DlxModalSlideshowNavigation;
