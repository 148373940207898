import SlideshowNavigation from '../../common/slideshow/slideshow-navigation';

class DlxSlideshowNavigation extends SlideshowNavigation {

	constructor({
		root,
		element,
		navigationAttribute = 'goTo',
		handleSwipeAttribute = 'handleSwipe',
		modalContentAttribute = 'modalContent',
		currentClass = 'current',
		stopPropagation = true,
		capture = true,
		nextClass = 'next',
		activeClass = 'active',
		modalContextName = 'modalGallery',
		modalIdSuffix = '-modal',
		clickTolerance = 20
	}) {
		super({
			root: root,
			element: element,
			navigationAttribute: navigationAttribute,
			handleSwipeAttribute: handleSwipeAttribute,
			currentClass: currentClass,
			stopPropagation: stopPropagation,
			capture: capture
		});
		this.modalContentAttribute = modalContentAttribute;
		this.nextClass = nextClass;
		this.activeClass = activeClass;
		this.modalContextName = modalContextName;
		this.modalIdSuffix = modalIdSuffix;
		this.clickTolerance = clickTolerance;

		this.firstClick = true;
		this.slideshowSelected = false;
		this.mouseDownPosition = {x: 0, y: 0};
	}


	prepare() {
		super.prepare();
		const mainSlideshow = this.slideshows[0];
		this.listeners.slideDown = this.events.on(mainSlideshow.getElement(), this.dataSelector(mainSlideshow.getSlideAttribute()), 'mousedown touchstart', this.onSlideDown.bind(this));
		this.listeners.slideClick = this.events.on(mainSlideshow.getElement(), this.dataSelector(mainSlideshow.getSlideAttribute()), 'click', this.onSlideClick.bind(this));

		this.slideshow = this.components.getComponent(this.element, 'Slideshow');

		const id = this.dataAttr().get('for')[0] + this.modalIdSuffix;
		this.modalSlideshow = this.components.queryComponent(this.root, this.dataSelector('id', id));
		this.modalSlideshow.setSurviveDetached(true);
		const modalContent = this.root.querySelector(this.dataSelector(this.modalContentAttribute));
		const slideshowElement = this.modalSlideshow.getElement();
		modalContent.appendChild(slideshowElement);
		this.modalSlideshow.load();
		this.listeners.context = this.events.on(this.root, 'context:switchcomplete', this.onContextSwitch.bind(this));
	}


	clear() {
		if (this.modalSlideshow) {
			const element = this.modalSlideshow.getElement();
			element.parentNode.removeChild(element);
		}
	}

	onSlideDown(event) {
		this.mouseDownPosition.x = event.clientX;
		this.mouseDownPosition.y = event.clientY;
	}



	onSlideClick(event, target) {
		if (Math.abs(event.clientX - this.mouseDownPosition.x) <= this.clickTolerance && Math.abs(event.clientY - this.mouseDownPosition.y) <= this.clickTolerance) {
			event.preventDefault();
			if (this.firstClick) {
				this.firstClick = false;
				this.modalSlideshow.setSurviveDetached(false);
			}
			if (this.cssData(target).get('allowZoom', false)) {
				const slide = this.getComponent(target);
				const index = slide.getIndex();
				this.slideshowSelected = true;
				this.modalSlideshow.goTo(index);
				this.classList(this.modalSlideshow.getElement()).add(this.activeClass);
				this.contexts.push(this.modalContextName);
			} else {
				const slide = this.getComponent(target);
				const index = slide.getIndex();
				this.slideshow.goTo(index);
			}
		}
	}


	onContextSwitch(event) {
		if (event.detail.previousContext.getName() === this.modalContextName && this.slideshowSelected) {
			this.classList(this.modalSlideshow.getElement()).remove(this.activeClass);
			this.slideshowSelected = false;
		}
	}

}

export default DlxSlideshowNavigation;
