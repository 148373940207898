import httpRequest from 'superagent';
import PageComponent from '../../common/component/page-component';


class ContactForm extends PageComponent {

	constructor({root, element, busyClass = 'busy', messageAttribute = 'message',  buttonAttribute = 'button'}) {
		super({root: root, element: element});
		this.busyClass = busyClass;
		this.messageAttribute = messageAttribute;
		this.buttonAttribute = buttonAttribute;

		this.busy = false;
	}


	prepare() {
		this.message = this.element.querySelector(this.dataSelector(this.messageAttribute));
		this.button = this.element.querySelector(this.dataSelector(this.buttonAttribute));
		this.listeners.submit = this.events.on(this.element, 'submit', this.onSubmit.bind(this));
	}


	onSubmit(event) {
		event.preventDefault();
		if (!this.busy) {
			this.toggleBusy();
			const data = this.getFormData();
			const json = JSON.stringify(data);
			const req = httpRequest.post(this.element.action);

			for (const field of this.element.elements) {
				// Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
				if (field.type === 'file') {
					if(field.files.length) {
						const f = field.files[0];
						console.log(field.name, f);
						req.attach(field.name, f);
					}
				}
			}

			req.field('data', json)
			.set('X-Requested-With', 'XMLHttpRequest')
			.set('Accept', 'application/json')
			.end((error, response) => {
				this.toggleBusy();
				if (error || !response.ok) {
					console.error('response error', error, response);
				} else {
					const body = response.body;
					this.message.textContent = body.message;
					this.button.disabled = true;
				}
			});
		}
	}


	getFormData() {
		const data = {};
			// Loop through each field in the form
		for (const field of this.element.elements) {
			// Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields
			//if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') {
			if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') {
				continue;
			}

			if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
				data[field.name] = field.value;
			}
		}
		return data;
	}


	toggleBusy() {
		this.busy = !this.busy;
		this.classList().toggle(this.busyClass, this.busy);
	}
}

export default ContactForm;
