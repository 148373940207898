import Context from '../../common/context/context';

class TrackersContext extends Context {
	constructor({name, root, popOnKey = false, fixedClass = 'fixed', activeClass = 'active'}) {
		super({name: name, root: root, popOnKey: false});
		this.activeClass = activeClass;
		this.fixedClass = fixedClass;
	}

	switchIn(otherContext) {
		const element = this.getElement();
		this.threeStateTransition(element).add(this.activeClass);
		window.scrollTo(0, 0);
		return otherContext;
	}


	switchOut(otherContext) {
		const element = this.getElement();
		this.classList(element).add(this.fixedClass);
		this.threeStateTransition(element).remove(this.activeClass).then(() => {
			this.classList(element).remove(this.fixedClass);
			return otherContext;
		});
		return otherContext;
	}
}

export default TrackersContext;
