import Player from '@vimeo/player';
import PageComponent from '../../common/component/page-component';

class BackgroundVideo extends PageComponent {
	constructor({
		root,
		element,
		urlData = 'vimeourl',
		videoData = 'video',
		activeClass = 'active',
		showContentDelay = 0.8,
		hideContentDelay = 0.2
	}) {
		super({root: root, element: element});
		this.urlData = urlData;
		this.videoData = videoData;
		this.activeClass = activeClass;
		this.showContentDelay = showContentDelay;
		this.hideContentDelay = hideContentDelay;
	}

	prepare() {
		this.image = this.getComponent('AsyncPicture');
		this.player = null;
		this.initiated = false;
		this.isSlideActive = false;
		this.isVideoCreated = false;
		this.videoElement = this.element.querySelector(this.dataSelector(this.videoData));
	}

	initialize() {
		this.loadImage();
		this.playVideo();
	}

	loadImage() {
		if(this.image) {
			this.image.on('load');
		}
	}

	createVideo() {
		if(this.isVideoCreated) return;
		const options = {
			responsive: false,
			controls: false,
			background: true,
			loop: true,
			autoplay: true,
			autopause: true,
			playsinline: true,
			muted: true,
			dnt: true
		};
		options.url = this.dataAttr(this.videoElement).get(this.urlData);
		if(options.url) {
			this.player = new Player(this.videoElement, options);
			this.timeUpdateListener = this.player.on('timeupdate', this.onTimeUpdate.bind(this));
		}
		this.isVideoCreated = true;
	}

	clear() {
		if(this.player) {
			this.player.unload();
		}
	}

	//stops on context change
	stop() {
		this.pauseVideo();
	}

	//starts on context change
	start() {
		this.playVideo();
	}

	playVideo() {
		this.createVideo();
		if(!this.player) return;
		this.player.play();
	}

	pauseVideo() {
		if(!this.player) return;
		this.player.getPaused().then((paused) => {
			if(!paused) {
				this.player.pause();
			}
		});
	}

	setHeight(height) {
		this.height = height;
		this.element.style.height = this.height + 'px';
	}
}

export default BackgroundVideo;
