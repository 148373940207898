import {TweenMax} from 'gsap';
import keymaster from 'keymaster';
import {getViewportHeight} from '../../common/utils/size';
import PageComponent from '../../common/component/page-component';

class HomeSlides extends PageComponent {
	constructor({
		root,
		element,
		slidesElementData = 'slides',
		slideElementData = 'slide',
		initiatedClass = 'initiated',
		duration = 1.4,
		ease = 'Power2.easeInOut'
	}) {
		super({root: root, element: element});
		this.slidesElementData = slidesElementData;
		this.slideElementData = slideElementData;
		this.initiatedClass = initiatedClass;
		this.duration = duration;
		this.ease = ease;
	}

	prepare() {
		this.currentSlide = null;
		this.isAnimating = false;
		this.navigation = this.getComponent('HomeSlidesNav');
		this.homeHeadline = this.getComponent('HomeHeadline');
		this.slidesElement =  this.element.querySelector(this.dataSelector(this.slidesElementData));
		this.slidesComponents = this.components.queryComponents(this.element, this.dataSelector('slide'));
		this.slidesNo = this.slidesComponents.length;
		this.slidesInitiated = 0;
		this.timerComponent = this.getComponent('HomeTimer');
		this.isUserInteracted = false;

		this.idleTimer = this.getComponent('IdleTimer');

		this.isFirstImageLoaded = false;

		this.parallaxComponents = this.getComponents('HomeParallaxElement');
		this.parallaxComponentsNo = this.parallaxComponents.length;
		for(let i=0; i<this.parallaxComponentsNo; i++) {
			this.parallaxComponents[i].setParentElement(this.slidesElement);
		}

		this.classList(this.element).add(this.initiatedClass);

		this.listeners.wheel = this.events.on(window, 'wheel', this.onWheel.bind(this));
		this.listeners.pandown = this.events.on(window, 'pandown', this.onPanDown.bind(this));
		this.listeners.pandup = this.events.on(window, 'panup', this.onPanUp.bind(this));
		this.listeners.touchmove = this.events.on(this.element, 'touchmove', this.onTouchMove.bind(this));
		this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
		this.listeners.menu = this.events.on(this.navigation.element, 'homeslidesnav:change', this.onMenuChange.bind(this));

		this.listeners.resizeLisener = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.listeners.orientation = this.events.on(window, 'orientationchange ', this.onOrientation.bind(this));

		this.listeners.timer = this.events.on(this.timerComponent.element, 'hometimer:complete', this.onTimer.bind(this));

		this.listeners.homeslidePlay = this.events.on(this.slidesComponents[0].element, 'homeslide:imageready', this.onSlideImageReady.bind(this));
		this.listeners.homeslidePlay = this.events.on(this.slidesComponents[0].element, 'homeslide:videoplay', this.onSlideVideoPlay.bind(this));

		this.updateNav();

		keymaster.setScope('home-slides');
		keymaster('up', 'home-slides', this.onUpButton.bind(this));
		keymaster('down', 'home-slides', this.onDownButton.bind(this));

		this.resize();
		this.showSlide(0, true);

		this.initializeSlides();
	}

	initializeSlides() {
		this.slidesComponents.map((slide) => slide.initialize());
	}

	onSlideImageReady() {
		if(!this.isFirstImageLoaded) {
			this.triggerReady();
			this.isFirstImageLoaded = true;
			this.idleTimer.initiate();
		}
	}

	onSlideVideoPlay() {
		this.timerComponent.startTimer();
		this.initiateAllVideos();
	}

	initiateAllVideos() {
		//initiate all videos (except the ones that are already initiated) after an interval,
		//this way the first video will player sooner
		this.slidesComponents.map((slide) => {
			console.log(slide.createVideo);
			if(slide.createVideo) {
				slide.createVideo();
			}
			return null;
		});
	}

	triggerReady() {
		this.events.trigger(this.element, 'homeslides:ready');
	}

	clear() {
		keymaster.unbind('up', 'home-slides');
		keymaster.unbind('down', 'home-slides');
	}

	onMenuChange(e) {
		this.showSlide(e.detail.id);
	}

	onResize(e) {
		this.resize();
		this.showSlide(this.currentSlide, true);
	}

	onOrientation(e) {
		this.resize();
		setTimeout(this.resize.bind(this), 100);
		this.showSlide(this.currentSlide, true);
	}

	resize() {
		this.vieportHeight = getViewportHeight();
		this.slidesComponents.map((slide) => slide.setHeight(this.vieportHeight));
	}

	onTouchMove(e) {
		e.preventDefault();
	}

	onWheel(e) {
		if(e.deltaY > 0) {
			this.showNext();
		} else {
			this.showPrev();
		}
		this.onUserInteraction();
	}

	onScroll(e) {
		e.preventDefault();
	}

	onPanDown(e) {
		e.preventDefault();
		this.showPrev();
		this.onUserInteraction();
	}

	onPanUp(e) {
		e.preventDefault();
		this.showNext();
		this.onUserInteraction();
	}

	onUpButton() {
		this.showPrev();
		this.onUserInteraction();
	}

	onDownButton() {
		this.showNext();
		this.onUserInteraction();
	}


	stop() {
		this.isStopped = true;
	}

	start() {
		this.isStopped = false;
	}

	showNext() {
		if(this.isAnimating || this.currentSlide === this.slidesNo - 1 || this.isStopped) return;
		this.showSlide(this.currentSlide + 1);
	}

	showPrev() {
		if(this.isAnimating || this.currentSlide === 0 || this.isStopped) return;
		this.showSlide(this.currentSlide - 1);
	}

	showSlide(slideId, isFastAnimation = false) {
		this.isAnimating = true;
		//const rect = this.slidesComponents[slideId].element.getBoundingClientRect();
		//const height = rect.bottom - rect.top;
		//const pos = 0.5 * (height - this.vieportHeight) + rect.top;

		const el = this.slidesComponents[slideId].element;
		//const height = el.offsetHeight;
		const pos = el.offsetTop;
		//const newValue = -this.vieportHeight*slideId+'px';
		const newValue = -pos + 'px';

		TweenMax.to(this.slidesElement, isFastAnimation?0:this.duration, {ease: this.ease, transform: 'translate3d(0,'+newValue+',0)',
			onStart: this.onAnimStart.bind(this),
			onUpdate: this.onAnimUpdate.bind(this),
			onComplete: this.onAnimComplete.bind(this)});

		if(this.currentSlide !== slideId) {
			if(this.slidesComponents[this.currentSlide]) {
				this.slidesComponents[this.currentSlide].pauseSlide();
			}
			this.slidesComponents[slideId].playSlide(isFastAnimation);
		}

		this.homeHeadline.setText(this.slidesComponents[slideId].getHeadline(), slideId>=this.currentSlide?'down':'up');

		this.currentSlide = slideId;
		this.updateNav();
	}

	onAnimComplete() {
		this.isAnimating = false;
		this.updateParallax();
	}

	onAnimStart() {
		this.updateParallax();
	}

	onAnimUpdate() {
		this.updateParallax();
	}

	updateParallax() {
		for(let i=0; i<this.parallaxComponentsNo; i++) {
			this.parallaxComponents[i].update();
		}
	}

	updateNav() {
		this.navigation.setButton(this.currentSlide);
	}

	onTimer(e) {
		if(this.currentSlide < this.slidesNo - 1) {
			this.showNext();
		} else {
			this.showSlide(0);
		}
		this.timerComponent.restart();
	}

	onUserInteraction() {
		this.timerComponent.stopTimer();
	}
}

export default HomeSlides;
