import DefaultContext from './default-context';
import MenuContext from './menu-context';
import ModalContext from './modal-context';
import ModalGalleryContext from './modal-gallery-context';
import SearchContext from './search-context';
import TrackersContext from './trackers-context';


export default (di) => {
	di
		.setType({type: DefaultContext, name: 'DefaultContext', parent: 'Context'})
		//.setType({type: MenuContext, name: 'MenuContext', parent: 'ModalContext'})
		.setType({type: ModalContext, name: 'ModalContext', parent: 'Context'})
		.setType({type: ModalGalleryContext, name: 'ModalGalleryContext', parent: 'Context'})
		.setType({type: SearchContext, name: 'SearchContext', parent: 'Context'})

		.setType({type: MenuContext, name: 'MenuContext', parent: 'ModalContext', params: {
				mediaQuery: di.lazyValue('menuMediaQuery', 'fail')
			}
		})
		.setType({type: TrackersContext, name: 'TrackersContext', parent: 'Context'})
	;
};
