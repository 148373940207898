import PageComponent from '../../common/component/page-component';

class SearchToggler extends PageComponent {
	constructor({
		root,
		element,
		mediaQuery,
		contextName = 'search',
		closeClass = 'close',
		showClass = 'searchOpen',
		searchTogglerButtonData = 'searchTogglerButton'
	}) {
		super({root: root, element: element});
		this.mediaQuery = mediaQuery;
		this.contextName = contextName;
		this.closeClass = closeClass;
		this.showClass = showClass;
		this.searchTogglerButtonData = searchTogglerButtonData;
		this.isVisible = false;
		this.isMatchingMedia = false;
	}

	prepare() {
		this.listeners.click = this.events.on(document.body, this.dataSelector(this.searchTogglerButtonData), 'click', this.onToggle.bind(this));
		this.listeners.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));
		this.isMatchingMedia  = this.checkMatchingMedia();
	}

	onResize(event) {
		this.checkContext();
	}

	onToggle(event, target) {
		event.preventDefault();
		if(this.isVisible) {
			this.close();
		} else {
			this.open();
		}
		this.isMatchingMedia = this.checkMatchingMedia();
	}

	checkContext() {
		if(this.isVisible && this.isMatchingMedia !== this.checkMatchingMedia()) {
			this.contexts.toggle(this.contextName);
			this.isMatchingMedia = this.checkMatchingMedia();
		}
	}

	checkMatchingMedia() {
		return matchMedia(this.mediaQuery).matches;
	}

	open() {
		this.isVisible = true;
		this.classList(document.body).add(this.showClass);

		if(this.checkMatchingMedia() && this.contexts.getCurrentContext().getName() !== this.contextName) {
			this.contexts.toggle(this.contextName);
		}
	}

	close() {
		this.isVisible = false;
		this.classList(document.body).remove(this.showClass);

		if(this.contexts.getCurrentContext().getName() === this.contextName) {
			this.contexts.pop();
		}
	}
}

export default SearchToggler;
