import domMixin from '../dom/dom-mixin';
import contextsMixin from './contexts-mixin';
import componentsMixin from '../component/components-mixin';
import componentConstants from '../component/_constants';


class Context extends componentsMixin(contextsMixin(domMixin())) {

	constructor({name, root, popOnKey = 'esc'}) {
		super();
		this.name = name;
		this.root = root;
		this.popOnKey = popOnKey;
		this.element = null;
		this.active = false;
	}


	injectComponents(components) {
		this.components = components;
	}


	injectContexts(contexts) {
		this.contexts = contexts;
		return this;
	}


	getElement() {
		if (!this.element || !this.root.contains(this.element)) {
			this.element = this.root.querySelector(this.dataSelector(componentConstants.contextAttribute, this.name));
		}
		if (!this.element) {
			throw new Error('Context ' + this.name + '\'s element not found');
		}
		return this.element;
	}


	getName() {
		return this.name;
	}


	getPopOnKey() {
		return this.popOnKey;
	}


	activate() {
		if (!this.active) {
			this.active = true;
			// activate components
			this.toggleComponentsActivation(true);
			this.events.trigger(this.getElement(), 'context:activate', {context: this});
		}
		return this;
	}


	deactivate() {
		if (this.active) {
			this.active = false;
			// deactivate components
			this.toggleComponentsActivation(false);
			this.events.trigger(this.getElement(), 'context:deactivate', {context: this});
		}
		return this;
	}


	toggleComponentsActivation(activate) {
		const method = activate ? 'activate' : 'deactivate';
		const components = this.components.queryComponents(this.getElement(), this.dataSelector(componentConstants.componentAttribute));
		for (let i = 0, end = components.length; i < end; i++) {
			const component = components[i];
			if (component) {
				const context = component.getContext();
				if (context && context.getName() === this.name) {
					component[method]();
				}
			}
		}
		return this;
	}


	isActive() {
		return this.active;
	}

}


export default Context;
