import {getScrollTop} from '../../common/utils/get-scroll';
import {getViewportHeight} from '../../common/utils/size';
import PageComponent from '../../common/component/page-component';

class MainMenu extends PageComponent {
	constructor({
		root,
		element,
		mediaQuery,
		fullWidthElementData = 'fullWidthElement',
		mainMenuMainData = 'mainMenuMain',
		mainMenuInnerData = 'mainMenuInner',
		headerData = 'header',
		menuHiddenClass = 'menuHidden'
	}) {
		super({root: root, element: element});
		this.mediaQuery = mediaQuery;
		this.fullWidthElementData = fullWidthElementData;
		this.mainMenuMainData = mainMenuMainData;
		this.mainMenuInnerData = mainMenuInnerData;
		this.headerData = headerData;
		this.menuHiddenClass = menuHiddenClass;
	}

	prepare() {
		this.maxElement = document.querySelector(this.dataSelector(this.fullWidthElementData));
		if(this.maxElement) {
			this.listeners.resizeLisener = this.events.on(window, 'window:resize', this.onResize.bind(this));
			this.listeners.scrollListener = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
			this.listeners.pageLoad = this.events.on(window, 'history:pagechange', this.onPageLoad.bind(this));

			this.mainMenuElement = this.element.querySelector(this.dataSelector(this.mainMenuMainData));
			this.innerElement = this.element.querySelector(this.dataSelector(this.mainMenuInnerData));
			this.header = document.querySelector(this.dataSelector(this.headerData));

			this.checkMenuPosition();
			this.checkMaxMenuWidth();
		}

		this.togglers = this.getComponents('Toggler');
		this.listeners.linkListener = this.events.on(this.element, 'a', 'click', this.onLinkClick.bind(this));
	}

	onLinkClick(event, target) {
		const href = target.getAttribute('href');
		if (href.charAt(0) !== '#') {
			if(this.togglers) {
				this.togglers.forEach((toggler) => {
					if(toggler.isToggled()) toggler.toggle();
				});
			}
		}
	}

	onPageLoad(e) {
		this.maxElement = document.querySelector(this.dataSelector(this.fullWidthElementData));
		this.checkMenuPosition();
		this.checkMaxMenuWidth();
	}

	onResize(event) {
		this.checkMenuPosition();
		this.checkMaxMenuWidth();
	}

	onScroll(event) {
		this.checkMenuPosition();
	}

	checkMaxMenuWidth() {
		if (!matchMedia(this.mediaQuery).matches) {
			this.isMenuInDesktopView = true;
		} else {
			this.isMenuInDesktopView = false;
		}
	}


	checkMenuPosition() {
		if(this.maxElement) {
			this.mainElementRect = this.mainMenuElement.getBoundingClientRect();
			this.innerElementRect = this.innerElement.getBoundingClientRect();
			this.maxElementRect = this.maxElement.getBoundingClientRect();

			if(this.isMenuInDesktopView && this.innerElementRect.bottom > this.maxElementRect.top
				&& getScrollTop() > getViewportHeight()) { //prevent menu disappearing on short pages (like error page)
				const height = Math.max(0, this.mainElementRect.height - (this.mainElementRect.bottom - this.maxElementRect.top));
				this.mainMenuElement.style.height = height + 'px';
				this.classList(this.header).add(this.menuHiddenClass);
			} else {
				this.showMenu();
			}
		}
	}

	showMenu() {
		this.mainMenuElement.style.height = 'auto';
		this.classList(this.header).remove(this.menuHiddenClass);
	}
}

export default MainMenu;
