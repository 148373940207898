import CaseStudy from './case-study';

export default (di) => {
	di
		.setType({type: CaseStudy, name: 'CaseStudy', parent: 'PageComponent', params: {
				mediaQuery: di.lazyValue('menuMediaQuery', 'fail')
			}
		})
	;
};
