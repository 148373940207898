import PageComponent from '../component/page-component';
import {isNumber} from '../utils/types';
import {wait} from '../utils/wait';


class Slideshow extends PageComponent {
    constructor({
		root,
		element,
		loaderName = 'FirstBefore',
		animatorName = '',
		autoplayerName = '',
		slideAttribute = 'slide',
		interactiveClass = 'interactive',
		loadedClass = 'loaded',
		autoload = true,
		circular = true,
		autoplay = false,
		autoplayFirstDelay = 0,
		autoplayDefaultDuration = 1,
		setCurrentSlide = 0,
	}) {
		super({root: root, element: element});

		this.slideAttribute = slideAttribute;
		this.interactiveClass = interactiveClass;
		this.loadedClass = loadedClass;
		this.defaults.loader = loaderName;
		this.defaults.animator = animatorName;
		this.defaults.autoplayer = autoplayerName;
		this.defaults.autoload = autoload;
		this.defaults.circular = circular;
		this.defaults.autoplay = autoplay;
		this.defaults.autoplayDefaultDuration = autoplayDefaultDuration;
		this.defaults.autoplayFirstDelay = autoplayFirstDelay;
		this.defaults.setCurrentSlide = setCurrentSlide;
		this.interactive = false;
		this.busy = false;
		this.loading = false;
		this.loaded = false;
        this.slides = [];
		this.slidesCount = 0;
		this.firstAutoplay = true;
		this.currentIndex = null;
		this.currentSlide = null;

		this.loaderFactory = null;
		this.animatorFactory = null;
		this.autoplayerFactory = null;

		this.loader = null;
		this.animator = null;
		this.autoplayer = null;
	}


	injectLoaderFactory(loaderFactory) {
		this.loaderFactory = loaderFactory;
	}


	injectAnimatorFactory(animatorFactory) {
		this.animatorFactory = animatorFactory;
	}


	injectAutoplayerFactory(autoplayerFactory) {
		this.autoplayerFactory = autoplayerFactory;
	}


    prepare() {
		this.slides = this.queryComponents(this.dataSelector(this.slideAttribute));
		this.slidesCount = this.slides.length;
		if (this.slidesCount) {
			for (let i = 0; i < this.slidesCount; i++) {
				this.slides[i].setIndex(i);
				this.slides[i].setSlideshow(this);
			}
			const setCurrentSlide = this.dataAttr().get('setCurrentSlide');
			if (setCurrentSlide !== false && setCurrentSlide !== null && setCurrentSlide >= 0 && setCurrentSlide < this.slidesCount) {
				this.setCurrentSlide(setCurrentSlide);
			}
		}
		const animator = this.getAnimator();
		const autoplayer = this.getAutoplayer();
		if (animator) {
			animator.setup(this);
		}
		if (autoplayer) {
			autoplayer.setup(this, this.dataAttr().get('autoplayDefaultDuration'));
		}
		this.openAsyncEvent('load');
		this.openAsyncEvent('interactive');

		if (this.dataAttr().get('autoload')) {
			this.load();
		}
	}


	clear() {
		for (const obj of [this.getLoader(), this.getAnimator(), this.getAutoplayer()]) {
			if (obj) {
				obj.destroy();
			}
		}
	}


	getAutoplayer() {
		if (!this.autoplayer) {
			if (this.autoplayerFactory) {
				this.autoplayer = this.autoplayerFactory.newInstance(this.dataAttr().get('autoplayer'));
			}
		}
		return this.autoplayer;
	}


	getAnimator() {
		if (!this.animator) {
			if (this.animatorFactory) {
				this.animator = this.animatorFactory.newInstance(this.dataAttr().get('animator'));
			}
		}
		return this.animator;
	}


	getLoader() {
		if (!this.loader) {
			if (this.loaderFactory) {
				this.loader = this.loaderFactory.newInstance(this.dataAttr().get('loader'));
			}
		}
		return this.loader;
	}


	start(first) {
		if (this.interactive) {
			this.playAutoplay();
		}
	}


	stop() {
		if (this.interactive) {
			this.pauseAutoplay();
		}
	}


	load() {
		if (!this.loading && !this.loaded) {
			this.loading = true;
			const loaded = () => {
				this.loading = false;
				this.loaded = true;
				this.classList(this.element).add(this.loadedClass);
				this.closeAsyncEvent('load');
				this.events.trigger(this.element, 'slideshow:load', {component: this});
			};
			const loader = this.getLoader();
			if (!loader) {
				loaded();
			} else {
				loader.load(this).then(() => loaded());
			}
		}
		return this.on('load');
	}


	getSlides() {
		return this.slides;
	}


	getSlide(index) {
		if (index >= 0 && index < this.slidesCount) {
			return this.slides[index];
		}
		throw new Error('Slide index ' + index + ' out bounds');
	}


	getSlidesCount() {
		return this.slidesCount;
	}


	getSlideAttribute() {
		return this.slideAttribute;
	}


	isCircular() {
		return this.dataAttr().get('circular');
	}


	setInteractive(value = true) {
		this.interactive = !!value;
		this.classList(this.element).toggle(this.interactiveClass, this.interactive);
		if (this.interactive && this.active) {
			this.playAutoplay();
		}
		return this;
	}


	setCurrentSlide(slide) {
		if (this.currentSlide) {
			this.currentSlide.setCurrent(false);
		}
		if (isNumber(slide)) {
			if (slide >= 0 && slide < this.slidesCount) {
				slide = this.slides[slide];
			} else {
				slide = null;
			}
		}
		if (slide) {
			this.currentSlide = slide;
			this.currentIndex = slide.getIndex();
			slide.setCurrent();
		}
		return this;
	}


	getCurrentSlide() {
		return this.currentSlide;
	}


	getCurrentIndex() {
		return this.currentIndex;
	}


    next() {
		if (!this.interactive || this.busy || this.slidesCount < 2 || (!this.dataAttr().get('circular') && this.currentIndex >= this.slidesCount - 1)) {
			return Promise.resolve();
		}
        return this.goTo((this.currentIndex + 1) % this.slidesCount, 1);
    }


    prev() {
		if (!this.interactive || this.busy || this.slidesCount < 2 || (!this.dataAttr().get('circular') && this.currentIndex === 0)) {
			return Promise.resolve();
		}
        return this.goTo((this.currentIndex === 0 ? this.slidesCount : this.currentIndex) - 1, -1);
    }


    goTo(newSlide, direction = null) {
		const isNewSlideNumber = isNumber(newSlide);
		if (!this.interactive || this.busy || (isNewSlideNumber && (this.currentIndex === newSlide || newSlide < 0 || newSlide >= this.slidesCount)) || (!isNewSlideNumber && newSlide === this.currentSlide)) {
			return Promise.resolve();
		}
		if (isNewSlideNumber) {
			newSlide = this.slides[newSlide];
		}
        return this.switch(this.currentSlide, newSlide, direction);
    }


    switch(currentSlide, newSlide, direction = null) {
		this.busy = true;
		const beforeEvent = this.events.trigger(this.element, 'slideshow:beforeswitch', {
			component: this,
			currentSlide: currentSlide,
			newSlide: newSlide,
			direction: direction
		});
		const autoplayer = this.getAutoplayer();
		if (!beforeEvent.defaultPrevented) {
			if (autoplayer && this.dataAttr().get('autoplay')) {
				autoplayer.resetTimer();
			}
			const animator = this.getAnimator();
			const promise = animator ? animator.beforeSwitch(currentSlide, newSlide, direction).then(() => animator.switch(currentSlide, newSlide, direction)) : Promise.resolve();
			return promise.then(() => {
				this.setCurrentSlide(newSlide);
				this.events.trigger(this.element, 'slideshow:afterswitch', {
					component: this,
					currentSlide: currentSlide,
					newSlide: newSlide,
					direction: direction
				});
				this.busy = false;
			});
		} else {
			this.busy = false;
			return Promise.resolve();
		}
    }


    playAutoplay() {
		const autoplayer = this.getAutoplayer();
		if (autoplayer && this.dataAttr().get('autoplay')) {
			const promise = this.firstAutoplay ? wait(this.dataAttr().get('autoplayFirstDelay')) : Promise.resolve();
			this.firstAutoplay = false;
			return promise.then(() => {
				if (this.active) {
					autoplayer.play();
				}
			});
		}
		return Promise.resolve();
    }


    pauseAutoplay() {
		const autoplayer = this.getAutoplayer();
		if (autoplayer) {
			autoplayer.pause();
		}
		return Promise.resolve();
	}


	enableAutoplay() {
		this.dataAttr().set('autoplay', true);
		return this.playAutoplay();
	}


	disableAutoplay() {
		this.dataAttr().set('autoplay', false);
		return this.pauseAutoplay();
	}

}


export default Slideshow;
