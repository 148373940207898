import {TweenMax} from 'gsap';
import 'gsap/ScrollToPlugin';
import {getScrollTop} from '../utils/get-scroll';

import PageComponent from '../component/page-component';

class Accordion extends PageComponent {

	constructor({
		root,
		element,
		togglerComponentName = 'Toggler',
		allowOnlyOneOpened = true,
		beforeToggleEvent = 'accordion:beforetoggle',
		toggleEvent = 'accordion:toggle',
		// when allowing only one, it's better to use a pure JS animation
		// to obtain a perfect sync with the scroll animation
		disableCssTransition = true
	}) {
		super({root: root, element: element});
		this.defaults.togglerComponentName = togglerComponentName;
		this.defaults.allowOnlyOneOpened = allowOnlyOneOpened;
		this.defaults.beforeToggleEvent = beforeToggleEvent;
		this.defaults.toggleEvent = toggleEvent;
		this.defaults.disableCssTransition = disableCssTransition;
		this.togglersMap = new Map();
		this.currentToggled = null;
		this.busy = false;
		this.allowedTogglers = [];
	}


	prepare() {
		const data = this.dataAttr().getAll();
		if (data.allowOnlyOneOpened) {
			const togglers = this.getComponents(data.togglerComponentName);
			let i = 0;
			for (const toggler of togglers) {
				const toggledComponents = toggler.getToggledComponents();
				if (toggledComponents.length && data.disableCssTransition) {
					toggledComponents[0].disableCssTransition();
				}
				this.togglersMap.set(toggler, {order: i, toggledComponents: toggledComponents});
				if (toggler.isToggled()) {
					this.currentToggled = toggler;
					break;
				}
				i++;
			}
			this.listeners.beforeToggle = this.events.on(this.element, 'toggler:beforetoggle', this.onBeforeToggle.bind(this));
		}
	}


	onBeforeToggle(event) {
		if (this.busy && this.allowedTogglers.indexOf(event.detail.component) < 0) {
			event.preventDefault();
			return false;
		}
		if (!this.busy && this.allowedTogglers.length === 0) {
			// prevent the first and manually issue it again, because we want to keep everything in sync
			event.preventDefault();
			this.toggle(event.detail.component, event.detail.intention);
			return false;
		}
		return true;
	}


	toggle(newToggler, value = null) {
		if (value === null) {
			value = !newToggler.isToggled();
		}
		const data = this.dataAttr().getAll();
		return Promise.resolve().then(() => {
			if (this.busy) {
				return false;
			}
			this.busy = true;

			const beforeEvent = this.events.trigger(this.element, data.beforeToggleEvent, {component: this, toggler: newToggler});
			if (beforeEvent.defaultPrevented) {
				this.busy = false;
				return false;
			}
			this.allowedTogglers = [newToggler];
			const promises = [];
			promises.push(newToggler.toggle());
			// if we allow only one item to be opened and the previously opened element is before the new one,
			// then we animate also the scroll position in order to take the new one in the same position
			// in respect to the viewport
			if (data.allowOnlyOneOpened && value && this.currentToggled) {
				this.allowedTogglers.push(this.currentToggled);
				promises.push(this.currentToggled.toggle());
				const currentEntry = this.togglersMap.get(this.currentToggled);
				const newEntry = this.togglersMap.get(newToggler);
				if (currentEntry.order < newEntry.order) {
					const currentContent = currentEntry.toggledComponents[0];
					const diff = currentContent.getWrapperHeightDiff(true);
					const heightDiff = diff.currentHeight - diff.newHeight;
					const scroll = getScrollTop() - heightDiff;
					TweenMax.to(window, currentContent.getDuration(), {scrollTo: {y: scroll, autoKill: true}});
				}
			}

			return Promise.all(promises).then(() => {
				this.allowedTogglers = [];
				if (value) {
					this.currentToggled = newToggler;
				} else {
					this.currentToggled = null;
				}
				this.busy = false;
				this.events.trigger(this.element, data.toggleEvent, {component: this, toggler: newToggler});
			});
		});
	}

}

export default Accordion;
