const asyncEventMixin = (Base = null) => class extends Base {

	initAsyncEvents() {
		this.asyncEvents = {};
		this.asyncEventsPromises = {};
		this.asyncEventsTriggers = {};
	}


	on(name) {
		if (name in this.asyncEvents) {
			if (!this.asyncEvents[name].pending) {
				return Promise.resolve(...this.asyncEvents[name].values);
			}
			return this.asyncEventsPromises[name];
		}
		throw new Error('Async Event "' + name + '" not defined');
	}


	openAsyncEvent(name, ...values) {
		this.asyncEvents[name] = {pending: true, values: values};
		this.asyncEventsPromises[name] = new Promise((resolve) => {
			this.asyncEventsTriggers[name] = resolve;
		});
		return this;
	}


	hasAsyncEvent(name) {
		return (name in this.asyncEvents);
	}

	hasAsyncEventPending(name) {
		return (name in this.asyncEvents && this.asyncEvents[name].pending);
	}


	closeAsyncEvent(name, ...values) {
		if (name in this.asyncEvents && this.asyncEvents[name].pending) {
			this.asyncEvents[name].pending = false;
			this.asyncEvents[name].values = values;
			this.asyncEventsTriggers[name](...values);
			delete this.asyncEventsPromises[name];
			delete this.asyncEventsTriggers[name];
		} else {
			throw new Error('Async Event "' + name + '" not opened');
		}
		return this;
	}


	destroyAsyncEvents() {
		this.asyncEvents = null;
		this.asyncEventsPromises = null;
		this.asyncEventsTriggers = null;
	}
};

export default asyncEventMixin;
