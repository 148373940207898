import PageComponent from '../../common/component/page-component';

class ClassToggler extends PageComponent {
	constructor({
					root,
					element,
					activeClass = 'active'
				}) {
		super({root: root, element: element});
		this.activeClass = activeClass;
	}

	prepare() {
	}

	toggle() {
		this.classList(this.element).toggle(this.activeClass);
	}
}

export default ClassToggler;
