import Hammer from 'hammerjs';
import Events from './events';


class EventsAndGestures extends Events {

    constructor({rafEvents = ['scroll', 'resize'], hammerConfig = {}} = {}) {
		super({rafEvents: rafEvents});
		// needed to use event delegation
        hammerConfig.domEvents = true;
        this.hammer = new Hammer(document, hammerConfig);
				this.hammer.get('pan').set({threshold: 3});
		this.Hammer = Hammer;
		this.gestures = Hammer;
    }

}

export default EventsAndGestures;
